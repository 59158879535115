import React from 'react'

import { useSelector } from 'react-redux';

import classes from './Button.module.scss';

function Button({ children, className, onClick }) {
    const theme = useSelector(({ ui }) => ui.theme);

    const styles = !className ? `${classes.button} ${classes[theme]}` : `${className} ${classes.button} ${classes[theme]}`

    return (
        <div onClick={onClick && onClick} className={`${styles}`}>
            {children}
        </div>
    )
}

export default Button