import React from 'react'

import Switch from '../UI/Switch/Switch';

import { useSelector, useDispatch } from 'react-redux';
import { changeTheme } from '../../redux/reducers/uiSlice';

import classes from './ThemeSwitcher.module.scss';
import LightIcon from './sun.png';
import MoonIcon from './moon.png';

export default function ThemeSwitcher() {
    const dispatch = useDispatch();
    const currentTheme = useSelector(({ ui }) => ui.theme)

    const isDark = currentTheme === 'dark';

    const changeMainTheme = (value) => {
        dispatch(changeTheme(value))
    }

    return (
        <div className={classes.switch}>
            <Switch onChange={changeMainTheme}
                defaultValue={isDark}
                onIcon={LightIcon}
                offIcon={MoonIcon}
            />
        </div>
    )
}
