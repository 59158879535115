import React, { useState, useEffect } from 'react'
import Album from '../Album';
import Loader from '../UI/Loader';
import useGetAlbumsByCategory from '../../hooks/useGetAlbumsByCategory';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import classes from './ListOfAlbums.module.scss';

function ListOfAlbums() {
    // const { id } = useParams();    
    const catId = useSelector(({ category }) => category.activeCategory)
    const albumsOrder = useSelector(({ category }) => category.albumsOrder)

    const { data: albumsData, loading } = useGetAlbumsByCategory(catId);

    const [albums, setAlbums] = useState();

    useEffect(() => {
        albumsData.sort((a, b) => {
            const indexA = albumsOrder.indexOf(a.id);
            const indexB = albumsOrder.indexOf(b.id);
            return indexA - indexB;
        });

        setAlbums(albumsData)

    }, [albumsData, albumsOrder])

    // console.log(albumsTest)
    return (
        <div className={classes.list}>
            {!loading ? albumsData.map((album) => (
                <Album key={album.id} album={album} />
            )) : <Loader />}

            {!loading && !albumsData.length ? <p className="empty-list">Альбоми відсутні</p> : ''}
        </div>
    )
}

export default ListOfAlbums