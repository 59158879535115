import useIsUserLogin from '../../../hooks/useIsUserLogin';

import { db, storage } from '../../../services/firebase/config';
import { doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { useSelector } from 'react-redux';
import LazyLoadImg from '../../LazyLoadImg';
import AnimationLayout from '../../AnimationLayout';
import Modal from '../../UI/Modal/Modal';

import delIcon from '../../../assets/images/del-icon.png';
import editIcon from '../../../assets/images/edit-icon.png';
import okIcon from '../../../assets/images/ok-icon.png'
import cancelIcon from '../../../assets/images/cancel-icon.png'

import classes from './Advantage.module.scss';
import { useState } from 'react';

function Advantage({ item, idx }) {
    const [isEditing, setIsEditing] = useState(false);
    const [enteredEditText, setEnteredEditText] = useState(item.text);
    const [enteredEditTitle, setEnteredEditTitle] = useState(item.title);
    const [enteredEditImage, setEnteredEditImage] = useState(null);
    const [imgDownloaded, setImgDownloaded] = useState(false)
    const user = useIsUserLogin();
    const theme = useSelector(({ ui }) => ui.theme)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const deleteAlbum = async (id) => {
        const coverRef = ref(storage, `${item.coverUrl}`);

        // const folderRef = ref(storage, `albumsImages/${album.id}/`);
        await deleteObject(coverRef).then(async () => {
            const imgRef = ref(storage, item.coverUrl);
            await deleteObject(imgRef)
            // File deleted successfully
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });

        await deleteDoc(doc(db, 'advantages', item.id)).then(() => {

        });
    }

    const saveEdited = () => {
        if (!enteredEditTitle.trim() || !enteredEditText.trim()) {
            return;
        }

        const docRef = doc(db, 'advantages', item.id)

        if (!enteredEditImage) {
            updateDoc(docRef, {
                title: enteredEditTitle,
                text: enteredEditText,
            })
        } else {
            const coverRef = ref(storage, `${item.coverUrl}`);

            deleteObject(coverRef).then(() => {
                // File deleted successfully
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });

            const storageRef = ref(storage, `advatagesImages/${Date.now() + '_' + enteredEditImage.name}`);
            uploadBytesResumable(storageRef, enteredEditImage).then(
                () => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        await updateDoc(docRef, {
                            title: enteredEditTitle,
                            text: enteredEditText,
                            coverUrl: downloadURL
                        });
                    })
                }
            );
        }

        cancelEditing();
    }

    const cancelEditing = () => {
        setIsEditing(false)
        setEnteredEditImage(null)
    }

    const imgUrl = !enteredEditImage ?
        `${item.coverUrl}` : URL.createObjectURL(enteredEditImage);

    const loaded = () => {
        setImgDownloaded(true)
    }
    const loading = () => {
        setImgDownloaded(false)
    }

    const showDeleteAlert = () => {
        console.log('click')
        console.log(isDeleteModalVisible)
        setIsDeleteModalVisible(true)
    }

    const cancelDelete = () => {
        setIsDeleteModalVisible(false)
    }

    return (
        <>
            <Modal isVisible={isDeleteModalVisible} toggleVisibility={cancelDelete} onSave={deleteAlbum} okBtnText={'Так'}>
                <div className={classes.deleteModalText}>
                    Ви впевнені, що бажаєте видалити?
                </div>
            </Modal>
            {idx % 2 === 0 ?
                <AnimationLayout>
                    <div className={`${classes[theme]} ${classes.advantage}`}>
                        <div className={classes.imgWrapper}>
                            <div className={classes.img}>
                                <div className={classes["img-wrapper"]}>
                                    {isEditing ? <img src={imgUrl} alt="some img" /> : <LazyLoadImg afterLoad={loaded} beforeLoad={loading} image={{ alt: 'advantage', src: item.coverUrl }} />}
                                </div>

                                {imgDownloaded ?
                                    <div className={classes["img-bg"]}></div>
                                    : ''
                                }
                            </div>

                            {isEditing && <div className={classes['edit-image']}>
                                <label htmlFor="cover">Картинка: </label>

                                <div className={classes.inputImgWrapper}>
                                    <input id="cover" type="file" onChange={(e) => { setEnteredEditImage(e.target.files[0]) }} />
                                </div>
                            </div>}
                        </div>
                        <div className={`${classes.info} ${classes.left}`}>
                            <div className={classes.title}>
                                {!isEditing ? item.title :
                                    <div className={classes.inputWrapper}>
                                        <input type="text" name="title" onChange={e => setEnteredEditTitle(e.target.value)} value={enteredEditTitle} />
                                        {!enteredEditTitle.trim() && <span> Введіть назву! </span>}
                                    </div>
                                }

                            </div>

                            <div className={classes.text}>
                                {!isEditing ? item.text :
                                    <div className={classes.inputWrapper}>
                                        <textarea name="text" id="text" cols="30" rows="10" onChange={(e) => { setEnteredEditText(e.target.value) }} value={enteredEditText}>
                                        </textarea>
                                        {!enteredEditText.trim() && <span> Введіть текст! </span>}
                                    </div>
                                }
                            </div>

                            {user && <div className={`${classes.left} ${classes.bottom}`}>
                                {!isEditing ?
                                    <>
                                        <div onClick={showDeleteAlert} className={`${classes.del} ${classes.btn}`}>
                                            <img src={delIcon} alt="delete" />
                                        </div>
                                        <div onClick={() => setIsEditing(true)} className={`${classes.edit} ${classes.btn}`}>
                                            <img src={editIcon} alt="edit" />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div onClick={cancelEditing} className={`${classes.btn} ${classes.cancel}`}>
                                            <img src={cancelIcon} alt='cancel' />
                                        </div>
                                        <div onClick={saveEdited} className={`${classes.btn} ${classes.ok}`}>
                                            <img src={okIcon} alt='okay' />
                                        </div>
                                    </>
                                }
                            </div>}
                        </div>
                    </div>
                </AnimationLayout>
                :
                <AnimationLayout>
                    <div className={`${classes[theme]} ${classes.advantage}`}>
                        <div className={`${classes.info} ${classes.right}`}>
                            <div className={classes.title}>
                                {!isEditing ? item.title :
                                    <input type="text" name="title" onChange={e => setEnteredEditTitle(e.target.value)} value={enteredEditTitle} />
                                }
                            </div>

                            <div className={classes.text}>
                                {!isEditing ? item.text :
                                    <textarea name="text" id="text" cols="30" rows="10" onChange={(e) => { setEnteredEditText(e.target.value) }} value={enteredEditText}>
                                    </textarea>
                                }

                                {user && <div className={`${classes["left-bottom"]} ${classes.bottom}`}>
                                    {!isEditing ?
                                        <>
                                            <div onClick={showDeleteAlert} className={`${classes.del} ${classes.btn}`}>
                                                <img src={delIcon} alt="delete" />
                                            </div>
                                            <div onClick={() => setIsEditing(true)} className={`${classes.edit} ${classes.btn}`}>
                                                <img src={editIcon} alt="edit" />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div onClick={cancelEditing} className={`${classes.btn} ${classes.cancel}`}>
                                                <img src={cancelIcon} alt='cancel' />
                                            </div>
                                            <div onClick={saveEdited} className={`${classes.btn} ${classes.ok}`}>
                                                <img src={okIcon} alt='okay' />
                                            </div>
                                        </>
                                    }
                                </div>}
                            </div>
                        </div>

                        <div className={classes.imgWrapper}>
                            <div className={classes.img}>
                                <div className={classes["img-wrapper"]}>
                                    {isEditing ? <img src={imgUrl} alt="some img" /> : <LazyLoadImg afterLoad={loaded} beforeLoad={loading} image={{ alt: 'advantage', src: item.coverUrl }} />}
                                </div>

                                {imgDownloaded ?
                                    <div className={classes["img-bg"]}></div>
                                    : ''
                                }
                            </div>

                            {isEditing && <div className={classes['edit-image']}>
                                <label htmlFor="cover">Картинка: </label>

                                <div className={classes.inputWrapper}>
                                    <input id="cover" type="file" onChange={(e) => { setEnteredEditImage(e.target.files[0]) }} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </AnimationLayout>}
        </>

    )
}

export default Advantage