import { useState, useEffect } from 'react'

import useGetCategories from '../../../hooks/useGetCategories';
import Button from '../../UI/Button/Button';
import editIcon from '../../../assets/images/edit-icon.png'
import delIcon from '../../../assets/images/del-icon.png'
import Modal from '../../UI/Modal/Modal';
import { collection, addDoc, getDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase/config';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";

import okIcon from '../../../assets/images/ok-icon.png'
import cancelIcon from '../../../assets/images/cancel-icon.png'

import classes from './EditCategories.module.scss';

function EditCategories() {
    const { data: categories } = useGetCategories();

    const [popupVisibility, setPopupVisibility] = useState(false);
    const [enteredCategory, setEnteredCategory] = useState('');
    const [isEditing, setIsEditing] = useState('');
    const [enteredEditCategory, setEnteredEditCategory] = useState('');
    const [allCategories, setAllCategories] = useState([]);

    const togglePopup = () => {
        setPopupVisibility(state => !state)
        setEnteredCategory('');
    }

    const toggleIsEditing = (id, name) => {
        setEnteredEditCategory(name)
        setIsEditing(id)
    }

    useEffect(() => {
        setAllCategories(categories);
        updateOrders()
    }, [categories, allCategories])

    function updateOrders() {
        allCategories.forEach((item, idx) => {
            if (item.orderIndex !== idx) {
                const docRef = doc(db, 'categories', item.id)
                updateDoc(docRef, {
                    orderIndex: idx,
                })
            }
        })
    }

    const addCategory = async (e) => {
        try {
            const docRef = await collection(db, 'categories');
            const result = await addDoc(docRef, {
                name: enteredCategory,
                orderIndex: categories.length + 1,
                albumsOrder: []
            });

            const docSnap = await getDoc(result);
        } catch (e) {
            console.log(e)
        }
        togglePopup();
    }

    const delCategory = async (id) => {
        await deleteDoc(doc(db, 'categories', id)).then(() => {
            // setDeletingAlbum(false)
        });
    }

    const saveEdited = () => {
        const docRef = doc(db, 'categories', isEditing)

        updateDoc(docRef, {
            name: enteredEditCategory
        })

        setIsEditing(false);
    }

    function onChange(sourceId, sourceIndex, targetIndex) {
        const nextState = swap(allCategories, sourceIndex, targetIndex);
        setAllCategories(nextState);
    }

    return (
        <div className={classes.wrapper}>
            <h2>Редагування категорій</h2>

            <Modal toggleVisibility={togglePopup} onSave={addCategory} isVisible={popupVisibility}>
                <div className={classes.form}>
                    <label htmlFor="category">Назва категорії: </label>
                    <input id="category" value={enteredCategory} onChange={e => setEnteredCategory(e.target.value)} type="text" />
                </div>
            </Modal>

            <div className={classes["add-button"]}>
                <Button onClick={togglePopup}> Додати категорію </Button>
            </div>

            <ul>
                <GridContextProvider onChange={onChange}>
                    <GridDropZone
                        id="items"
                        boxesPerRow={4}
                        rowHeight={60}
                        style={{ height: '50px' }}
                    >
                        {categories.map(category => (
                            <GridItem key={category.id}>
                                <li key={category.id} className={classes.category}>
                                    {isEditing !== category.id ?
                                        <>

                                            <div onClick={() => delCategory(category.id)} className={`${classes.del} ${classes.btn}`}>
                                                <img src={delIcon} alt="delete" />
                                            </div>
                                            <div onClick={() => toggleIsEditing(category.id, category.name)} className={`${classes.edit} ${classes.btn}`}>
                                                <img src={editIcon} alt="edit" />
                                            </div>

                                            <div className={classes.name}>
                                                {category.name}
                                            </div>
                                        </>
                                        :
                                        <div className={classes.input}>
                                            <div onClick={() => toggleIsEditing(false, '')} className={`${classes.btn} ${classes.cancel}`}>
                                                <img src={cancelIcon} alt='cancel' />
                                            </div>
                                            <div onClick={saveEdited} className={`${classes.btn} ${classes.ok}`}>
                                                <img src={okIcon} alt='okay' />
                                            </div>

                                            <input type="text" onChange={e => setEnteredEditCategory(e.target.value)} value={enteredEditCategory} />
                                        </div>
                                    }
                                </li>
                            </GridItem>
                        ))}
                    </GridDropZone>
                </GridContextProvider>
            </ul>
        </div>
    )
}

export default EditCategories