import React, { useState } from 'react'

import classes from './InputFile.module.scss';

function InputFile({ files, onChange, onClick, label }) {
    const countFilesUploaded = (files) => {
        let text;

        if (files === 1) {
            text = `вибрано ${files} файл`;
            return text;
        }

        if (files > 1 && files < 5) {
            text = `вибрано ${files} файли`;
            return text;
        }

        text = `вибрано ${files} файлів`;
        return text;
    }

    const handleFilesChange = (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const newImage = e.target.files[i];
            newImage["id"] = Math.random();
            onChange((prevState) => [...prevState, newImage]);
        }
    }

    return (
        <>
            <div className={classes.filesInput}>
                <label htmlFor="cover"> {label} </label>
                <input id="cover" multiple type="file" onChange={handleFilesChange} />
                <div className={classes.chosenFiles}>
                    {countFilesUploaded(files.length)}
                </div>
            </div>

            {files.length && onClick ? <button onClick={onClick}>
                Завантажити
            </button> : ''}
        </>
    )
}

export default InputFile