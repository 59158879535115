import { useEffect } from 'react';
import Button from '../UI/Button/Button';
import classes from './ListOfCategories.module.scss';
import useIsUserLogin from '../../hooks/useIsUserLogin';
import { useState } from 'react';
import useGetCategories from '../../hooks/useGetCategories';
import { useSelector, useDispatch } from 'react-redux';
import { changeCategory } from '../../redux/reducers/categorySlice';
import editIcon from '../../assets/images/edit-icon.png'
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Modal from '../UI/Modal/Modal';

import { db } from '../../services/firebase/config';
import { collection, addDoc, getDoc } from 'firebase/firestore';
import { NavLink } from 'react-router-dom';
import { EDIT_CATEGORIES_ROUTE } from '../../utils/consts';

function ListOfCategories() {
    const user = useIsUserLogin();
    const dispatch = useDispatch();
    let activeCatId = useSelector(({ category }) => category.activeCategory)
    const theme = useSelector(({ ui }) => ui.theme)
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: categories, countCategoriesAlbums, loading, countAllAlbums } = useGetCategories()

    useEffect(() => {
        // console.log(activeCatId)        
        // onChangeCategory('all')
        if (activeCatId !== 'all' && !id) {
            console.log('active: ', activeCatId)
            const findCategoryIdx = categories.filter((category) => {
                if (category.id === activeCatId) {
                    return category.orderIndex
                }
            })

            let test = findCategoryIdx[0];

            if (test) {
                navigate(`/albums/category/${test.orderIndex + 1}`);
            }
        }

        if (id) {
            const cat = categories[id - 1]
            if (cat) {
                onChangeCategory(cat)
            }
        }
    }, [categories]);

    const [popupVisibility, setPopupVisibility] = useState(false);
    const [enteredCategory, setEnteredCategory] = useState('');

    const addCategory = async (e) => {
        try {
            const docRef = await collection(db, 'categories');

            const result = await addDoc(docRef, {
                name: enteredCategory
            });

            const docSnap = await getDoc(result);
        } catch (e) {
            console.log(e)
        }

        togglePopup();
    }

    const togglePopup = () => {
        setPopupVisibility(state => !state)
        setEnteredCategory('');
    }

    const onChangeCategory = (category) => {
        dispatch(changeCategory(category))
    }

    return (
        <>
            <Modal toggleVisibility={togglePopup} onSave={addCategory} isVisible={popupVisibility}>
                <div className={classes.form}>
                    <label htmlFor="category">Назва категорії: </label>
                    <input id="category" value={enteredCategory} onChange={e => setEnteredCategory(e.target.value)} type="text" />
                </div>
            </Modal>

            <ul className={classes.categories}>
                {!loading && <li className={classes[theme]} onClick={() => onChangeCategory({ id: 'all', albumsOrder: [], name: 'Всі' })}>
                    <Link to={`/albums`}>
                        <Button className={activeCatId === 'all' ?
                            `${classes.catBtn} ${classes[`active-${theme}`]}`
                            : classes.catBtn}>

                            Всі

                            <span>{countAllAlbums}</span>
                        </Button>
                    </Link>
                </li>}
                {categories.map((category, index) => (
                    <li className={classes[theme]} onClick={() => onChangeCategory(category)} key={category.id}>
                        <Link to={`/albums/category/${index + 1}`}>
                            <Button
                                className={activeCatId === category.id ?
                                    `${classes.catBtn} ${classes[`active-${theme}`]}`
                                    : classes.catBtn}>

                                {/* {console.log("active: " + activeCatId)}
                                {console.log(category.id)} */}

                                {category.name}

                                <span className={classes.count}>
                                    {countCategoriesAlbums[category.id]}
                                </span>
                            </Button>
                        </Link>

                    </li>
                ))}

                {/* {user && <li onClick={togglePopup}><Button> + </Button></li>} */}
                {user && <NavLink to={EDIT_CATEGORIES_ROUTE.path}>
                    <li>
                        <Button className={classes["cat-edit-btn"]}>
                            <img src={editIcon} alt="edit" />
                        </Button>
                    </li>
                </NavLink>}

            </ul>
        </>
    )
}

export default ListOfCategories
