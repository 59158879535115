import { useState } from 'react'
import classes from './AddAdvantages.module.scss';
import Modal from '../../UI/Modal/Modal';
import useIsUserLogin from '../../../hooks/useIsUserLogin';

import { db, storage } from '../../../services/firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { collection, addDoc, getDoc } from 'firebase/firestore';
import InputFile from '../../UI/InputFile/InputFile';

function AddAdvantages() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [enteredImage, setEnteredImage] = useState([])
    const [enteredText, setEnteredText] = useState('')
    const [enteredTitle, setEnteredTitle] = useState('')
    const user = useIsUserLogin();

    const toggleModal = () => {
        setIsModalVisible(state => !state)

        setEnteredText('')
        setEnteredTitle('')
    }

    const onSave = async () => {
        // add album to firebase
        try {
            const docRef = await collection(db, 'advantages');
            const storageRef = ref(storage, `advantagesImages/${Date.now() + '_' + enteredImage.name}`);
            uploadBytesResumable(storageRef, enteredImage).then(
                () => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        const savedAlbum = await addDoc(docRef, {
                            title: enteredTitle,
                            text: enteredText,
                            coverUrl: downloadURL,
                        });

                        const docSnap = await getDoc(savedAlbum);
                    })

                    toggleModal();
                }
            );

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <Modal isVisible={isModalVisible} toggleVisibility={toggleModal} onSave={onSave}>
                <div className={classes.form}>
                    <div className={classes['form-group']}>
                        <label htmlFor="title">Заголовок: </label>
                        <input id="title" type="text" value={enteredTitle} onChange={(e) => { setEnteredTitle(e.target.value) }} />
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="text">Текст: </label>
                        <textarea id="text" cols="30" rows="5" value={enteredText} onChange={(e) => { setEnteredText(e.target.value) }} />
                    </div>

                    <div className={classes['form-group']}>

                        <label htmlFor="cover">Картинка: </label>
                        <input id="cover" type="file" onChange={(e) => { setEnteredImage(e.target.files[0]) }} />
                    </div>
                </div>
            </Modal>

            {user && <div onClick={toggleModal} className={classes["add-btn"]}> + </div>}
        </>
    )
}

export default AddAdvantages