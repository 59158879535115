import React from 'react'
import ListOfAdvantages from '../../components/ListOfAdvantages';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import LazyLoadImg from '../../components/LazyLoadImg';

import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sphere, MeshDistortMaterial } from '@react-three/drei';

import HomeImg from '../../assets/images/home-img.png'
import classes from './Home.module.scss';

const WordItem = styled.div`
    position: relative;
    transition: 0.3s;
    cursor: pointer;    
    height: 90px;
    margin: 0;
    padding: 0;
    /* display: flex;
    justify-content: center;
    align-items: center; */

    ${props => props.color === 'light' && `        
         -webkit-text-stroke: 1px black;
        `}
        ${props => props.color === 'dark' && `         
         -webkit-text-stroke: 1px rgba(255, 255, 255, 0.661);
    `}

    :hover {
      transform: scale(1.1);
    }

    ::after {
      content: "${(props) => props.text}";
      position: absolute;
      
      top: 0;
      left: 0;
      ${props => props.color === 'light' && `
         color:black;
         -webkit-text-stroke: 1px black;
        `}

        ${props => props.color === 'dark' && `
         color:white;
         -webkit-text-stroke: 1px rgba(255, 255, 255, 0.661);
        `}
      width: 0%;
      overflow: hidden;
      white-space: nowrap;
    }

    :hover {
      ::after {        
        animation: moveText 0.5s linear both;
        @keyframes moveText {
          to {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 1200px) {                
        font-size: 60px;                                      
        height: 60px;
    }

    @media (max-width: 800px) {                
        font-size: 35px;                        
        height: 40px;        
        width: 100%;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
        // font-family: Georgia;
    }

    @media (max-width: 500px) {                
        font-size: 28px;                        
        height: 40px;        
    }
`

const words = ['#Cучасні', 'випускні', 'альбоми']

function Home() {
  const theme = useSelector(({ ui }) => ui.theme)

  return (
    <>
      <div className={classes.card}>
        <div className={classes.text}>

          {words.map(word => (
            <WordItem key={word} text={word} color={theme}>{word}</WordItem>
          ))}
        </div>



        <div className={classes.image}>

          <img src={HomeImg} alt="Альбоми" />

          <div className={classes.bg}>
            <Canvas>
              <OrbitControls enableZoom={false} />
              <ambientLight intensity={1} />
              <directionalLight position={[3, 2, 1]} />
              <Sphere args={[1, 100, 200]} scale={2.6}>
                <MeshDistortMaterial color={theme === 'dark' ? '#e3e3e3' : '#3f3f3f'} attach="material" distort={0.3} speed={2} />
              </Sphere>
            </Canvas>
          </div>
        </div>
      </div>

      <ListOfAdvantages />
    </>
  )
}

export default Home