import React from 'react';

import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import classes from './Logo.module.scss';
import LogoLight from './logo-light.png';
import LogoDark from './logo-dark.png';

export default function Logo() {
    const theme = useSelector(({ ui }) => ui.theme);

    const logoImg = theme === 'dark' ? LogoLight : LogoDark;

    return (
        <div className={classes.logo}>
            <NavLink to={"/"}>
                <img src={logoImg} alt="main logo" />
            </NavLink>
        </div>
    )
}
