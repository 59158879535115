import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// import firebase from 'firebase/app';

// const app = firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_APP_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// })

// export const auth = getAuth(app);
// export default app;

const firebaseConfig = {
    apiKey: "AIzaSyD0bAjaRpBBa7HYa4NUomII6V7kEgvKd7Q",
    authDomain: "albums-bb5e4.firebaseapp.com",
    projectId: "albums-bb5e4",
    storageBucket: "albums-bb5e4.appspot.com",
    messagingSenderId: "230783107763",
    appId: "1:230783107763:web:3016005bc84f97df98a6c6",
    measurementId: "G-HC8KDDK7RJ"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)