export const HOME_ROUTE = { name: 'Головна', path: '/' };
export const ALBUMS_ROUTE = { name: 'Альбоми', path: '/albums' };
export const CONTACTS_ROUTE = { name: 'Контакти', path: '/contacts' };

// admin
export const LOGIN_ROUTE = { name: 'Увійти', path: '/login' };
export const ADD_ALBUM_ROUTE = { name: 'Додати альбом', path: '/add_album' };
export const EDIT_ALBUM_INFO_ROUTE = { name: 'Редагувати інформацію про альбом', path: '/edit/info' };
export const EDIT_ORDER_OF_ALBUMS_ROUTE = { name: 'Редагування послідовності альбомів', path: '/edit/orderofalbums' };
export const EDIT_ALBUM_PHOTOS_ROUTE = { name: 'Редагувати фотографій альбому', path: '/edit/photos' };
export const EDIT_CATEGORIES_ROUTE = { name: 'Категорії', path: '/edit/categories' };

export const MENU_LINKS = [
    HOME_ROUTE,
    ALBUMS_ROUTE,
    CONTACTS_ROUTE
]