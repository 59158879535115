import React, { useContext, useEffect, useRef } from 'react'
import classes from './UploadPhotos.module.scss';
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { db, storage } from '../../../../services/firebase/config';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { collection, addDoc, getDoc, doc } from 'firebase/firestore';
import useGetAlbumPhotos from '../../../../hooks/useGetAlbumPhotos';
import { deleteDoc } from 'firebase/firestore';
import { updateDoc } from 'firebase/firestore';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import EditMenu from '../EditMenu';
import DelIcon from '../../../../assets/images/del-icon.png';
import LazyLoadImg from '../../../LazyLoadImg';
import Loader from '../../../UI/Loader';
import Loading from '../../../Album/Loading';
import InputFile from '../../../UI/InputFile/InputFile';

function UploadPhotos() {
    const [enteredPhotos, setEnteredPhotos] = useState([])
    const [allPhotos, setAllPhotos] = useState([])
    const [uploadingPhotos, setUploadingPhotos] = useState(false)
    const [photoIsLoaded, setPhotoIsLoaded] = useState(false)
    const [rowWidth, setRowWidth] = useState(4)
    // const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // const [photoIsLoading, setPhotoIsLoading] = useState(true)

    const { id } = useParams();

    const { data: photos, loading } = useGetAlbumPhotos(id);

    useEffect(() => {
        setAllPhotos(photos);
        updateOrders()
    }, [photos, allPhotos])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        if (windowWidth < 1500) {
            setRowWidth(3);
        }

        if (windowWidth < 1000) {
            setRowWidth(2);
        }

        if (windowWidth < 650) {
            setRowWidth(1);
        }

        if (windowWidth > 1500) {
            setRowWidth(4);
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const uploadPhotos = () => {
        setUploadingPhotos(true);
        enteredPhotos.map((photo, index) => {
            const docRef = collection(db, 'photos');
            const storageRef = ref(storage, `albumsImages/${Date.now() + '_' + photo.name}`);
            uploadBytesResumable(storageRef, photo).then(
                () => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        const addedPhoto = await addDoc(docRef, {
                            albumId: id,
                            imgUrl: downloadURL,
                            orderIndex: photos.length + 1
                        })
                        const docSnap = await getDoc(addedPhoto);
                        setUploadingPhotos(false);
                        setEnteredPhotos([])
                    });
                })
        });
    }

    const delPhoto = async (url, id) => {
        const photoRef = ref(storage, `${url}`);

        await deleteObject(photoRef).then(async () => {
            await deleteDoc(doc(db, 'photos', id));
            // File deleted successfully
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    function onChange(sourceId, sourceIndex, targetIndex) {
        const nextState = swap(allPhotos, sourceIndex, targetIndex);
        setAllPhotos(nextState);
    }

    function updateOrders() {
        allPhotos.forEach((photo, idx) => {
            if (photo.orderIndex !== idx) {
                const docRef = doc(db, 'photos', photo.id)
                updateDoc(docRef, {
                    orderIndex: idx,
                })
            }
        })
    }

    const imgIsLoaded = () => {
        setPhotoIsLoaded(true)
    }

    return (
        <>
            <h3> Фотографії </h3>
            <EditMenu id={id} active={'photos'} />

            <div className={classes.form}>
                <InputFile
                    files={enteredPhotos}
                    onChange={setEnteredPhotos}
                    onClick={uploadPhotos}
                    label={"Оберіть фотографії"}
                />

                <div className={classes.photos}>
                    {!uploadingPhotos ? <GridContextProvider onChange={onChange}>
                        <GridDropZone
                            id="items"
                            boxesPerRow={rowWidth}
                            rowHeight={320}
                            style={{ height: 320 * Math.ceil(photos.length / rowWidth), width: "100%" }}
                        >
                            {allPhotos.map(photo => (
                                <GridItem key={photo.id}>
                                    <div key={photo.id} className={classes.photo} onDragStart={(e) => { e.preventDefault() }}>
                                        {photoIsLoaded && <div className={classes.del} onClick={() => delPhoto(photo.imgUrl, photo.id)}>
                                            <div className={classes["del-img"]} >
                                                <img src={DelIcon} alt="delete" />
                                            </div>
                                        </div>}

                                        {photoIsLoaded ? '' : <div className={classes.loading}><Loading /></div>}

                                        <LazyLoadImg
                                            image={{ alt: 'photo', src: photo.imgUrl }}
                                            afterLoad={imgIsLoaded}
                                        />
                                    </div>
                                </GridItem>
                            ))}
                        </GridDropZone>
                    </GridContextProvider> : <Loader />}

                    {!allPhotos.length && <div className="empty">Фото відсутні</div>}
                </div>
            </div >
        </>
    )
}




export default UploadPhotos