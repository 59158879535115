import { useState, useEffect } from 'react'
import { db } from '../services/firebase/config'
import { collection, query, where, orderBy, onSnapshot, getCountFromServer } from 'firebase/firestore'

function useGetAlbumPhotos() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [countCategoriesAlbums, setCountCategoriesAlbums] = useState({});
    const [countAllAlbums, setCountAllAlbums] = useState(0);

    const albums = collection(db, "albums");

    const getCounts = async (categories) => {
        for (let i = 0; i < categories.length; i++) {
            const q = query(albums, where("categories", "array-contains-any", [categories[i].id]));
            const snapshot = await getCountFromServer(q);
            setCountCategoriesAlbums(prevState => (
                {
                    ...prevState,
                    [categories[i].id]: snapshot.data().count
                }
            ))
        }

        const albumsSnapshot = await getCountFromServer(albums);
        setCountAllAlbums(albumsSnapshot.data().count)
    }

    useEffect(() => {
        const getData = async () => {
            const q = query(collection(db, "categories"), orderBy("orderIndex", "asc"));
            onSnapshot(q, (querySnapshot) => {
                let allData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                getCounts(allData)
                setData(allData)
            });
        }

        getData()
        setLoading(false);
    }, [])

    return { data, loading, countCategoriesAlbums, countAllAlbums };
}

export default useGetAlbumPhotos