import React from 'react'

import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/config';

import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { MENU_LINKS } from '../../utils/consts';

import { LOGIN_ROUTE } from '../../utils/consts';

import useIsUserLogin from '../../hooks/useIsUserLogin';

import classes from './Menu.module.scss';

export default function Menu() {
    const theme = useSelector(({ ui }) => ui.theme);

    const user = useIsUserLogin();

    const logout = async () => {
        await signOut(auth)
    }

    return (
        <div className={`${classes.menu} ${classes[theme]}`}>
            <ul>
                {MENU_LINKS.map((link, idx) => (
                    <NavLink
                        key={idx + '_' + link.name}
                        className={({ isActive }) => isActive ? classes.active : ''}
                        to={link.path}><li> {link.name} </li>
                    </NavLink>
                ))}

                {/* {user?.email ? <NavLink><li onClick={logout}>Вийти</li></NavLink> : <NavLink
                    className={({ isActive }) => isActive ? classes.active : ''}
                    to={LOGIN_ROUTE.path}><li> {LOGIN_ROUTE.name} </li>
                </NavLink>} */}
            </ul>
        </div>
    )
}
