import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import useGetAlbumsByCategory from '../../../hooks/useGetAlbumsByCategory';
import { collection, addDoc, getDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../services/firebase/config';
import Button from '../../UI/Button/Button';
import { useDispatch } from 'react-redux';
import Alert from '../../UI/Alert';
import { changeCategory } from '../../../redux/reducers/categorySlice';
import BackArrow from '../../UI/BackArrow'
import { useNavigate } from "react-router-dom";

import classes from './ChangeOrderOfAlbums.module.scss';

const DUMB_DATA = [
    { id: 1, text: 'бла бла бла' }, { id: 2, text: 'бла бла бла 2' }, { id: 3, text: 'бла бла бла 3' }, { id: 4, text: 'бла бла бла 4' }
]

function ChangeOrderOfAlbums() {
    const [allAlbums, setAllAlbums] = useState([])
    const [rowWidth, setRowWidth] = useState(6)
    const [rowHeight, setRowHeight] = useState(240)
    const [isAlertVisible, setIsAlertVisible] = useState(false)
    // const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const catId = useSelector(({ category }) => category.activeCategory)
    const albumsOrder = useSelector(({ category }) => category.albumsOrder)
    const categoryName = useSelector(({ category }) => category.name)
    const { data: albumsData, loading } = useGetAlbumsByCategory(catId);
    const navigate = useNavigate(-1);

    const dispatch = useDispatch();

    useEffect(() => {

        albumsData.sort((a, b) => {
            const indexA = albumsOrder.indexOf(a.id);
            const indexB = albumsOrder.indexOf(b.id);
            return indexA - indexB;
        });

        setAllAlbums(albumsData)
    }, [albumsData])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        if (windowWidth < 1500) {
            setRowWidth(5);
        }

        if (windowWidth < 1000) {
            setRowWidth(3);
        }

        if (windowWidth < 650) {
            setRowWidth(2);
        }

        if (windowWidth > 1500) {
            setRowWidth(6);
        }

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    function onChange(sourceId, sourceIndex, targetIndex) {
        const nextState = swap(allAlbums, sourceIndex, targetIndex);
        setAllAlbums(nextState);
    }
    function saveAlbumsOrder() {
        const albumsOrder = [];

        allAlbums.forEach((album, index) => {
            albumsOrder.push(album.id);
        })

        if (catId !== 'all') {
            const docRef = doc(db, 'categories', catId)
            updateDoc(docRef, {
                albumsOrder: albumsOrder,
            })
        }

        dispatch(changeCategory({ id: catId, albumsOrder: albumsOrder, name: categoryName }))

        setIsAlertVisible(true);
    }

    return (
        <div className={classes.list}>


            <Alert visibility={isAlertVisible} close={setIsAlertVisible}> Зміни збережено </Alert>

            <div className={classes.title}>
                <b>{`Сортування: ${categoryName}`}</b>
            </div>

            <div className={classes.btnSave}>
                <Button className={classes.btn} onClick={saveAlbumsOrder}> Зберегти </Button>
                <Button onClick={() => navigate(-1)}> Назад </Button>
            </div>
            {<GridContextProvider onChange={onChange}>
                <GridDropZone
                    id="items"
                    boxesPerRow={rowWidth}
                    rowHeight={rowHeight}
                    style={{ height: rowHeight * Math.ceil(allAlbums.length / rowWidth), width: "100%" }}
                >
                    {allAlbums.map(album => (
                        <GridItem key={album.id}>
                            <div className={classes.item} onDragStart={(e) => { e.preventDefault() }}>
                                <div className={classes.album}>
                                    <div className={classes.img}>
                                        <img src={album.coverUrl} alt="обкладинка" />
                                    </div>
                                    <div className={classes.title}>
                                        {album.name}
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    ))}
                </GridDropZone>
            </GridContextProvider>}

            <div className={classes.btnSave}>
                <Button className={classes.btn} onClick={saveAlbumsOrder}> Зберегти </Button>
                <Button onClick={() => navigate(-1)}> Назад </Button>
            </div>
        </div>
    )
}

export default ChangeOrderOfAlbums