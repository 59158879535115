import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { db, storage } from '../../services/firebase/config';
import { doc, deleteDoc } from 'firebase/firestore';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore'
import { NavLink } from 'react-router-dom';
import { EDIT_ALBUM_INFO_ROUTE } from '../../utils/consts';
import { ref, deleteObject } from 'firebase/storage'
import Button from '../UI/Button/Button';
import useIsUserLogin from '../../hooks/useIsUserLogin';
import AnimationLayout from '../AnimationLayout';
import LazyLoadImg from '../LazyLoadImg';
import EditIcon from '../../assets/images/edit-icon.png';
import DelIcon from '../../assets/images/del-icon.png';

import Modal from '../UI/Modal/Modal';

import Loading from './Loading';

import classes from './Album.module.scss';
import { useState } from 'react';
import Loader from '../UI/Loader';

function Album({ album }) {
    const theme = useSelector(({ ui }) => ui.theme)
    const user = useIsUserLogin();

    const [deletingAlbum, setDeletingAlbum] = useState(false)
    const [imgDownloaded, setImgDownloaded] = useState(true)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

    const deleteAlbum = async () => {
        let photos;

        const q = query(collection(db, "photos"), where("albumId", "==", album.id), orderBy("orderIndex", "asc"));
        onSnapshot(q, (querySnapshot) => {
            photos = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
        });

        setIsDeleteModalVisible(false);
        const id = album.id;
        const coverRef = ref(storage, `${album.coverUrl}`);
        setDeletingAlbum(true);

        // const folderRef = ref(storage, `albumsImages/${album.id}/`);
        await deleteObject(coverRef).then(async () => {
            for (let i = 0; i < photos.length; i++) {
                const imgRef = ref(storage, `${photos[i].imgUrl}`);
                await deleteObject(imgRef)

                await deleteDoc(doc(db, 'photos', photos[i].id));
            }
            // File deleted successfully
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
        await deleteDoc(doc(db, 'albums', id)).then(() => {
            setDeletingAlbum(false)
        });
    }

    const loaded = () => {
        setImgDownloaded(true)
    }
    const loading = () => {
        setImgDownloaded(false)
    }

    const showDeleteAlert = () => {
        setIsDeleteModalVisible(true)
    }

    const cancelDelete = () => {
        setIsDeleteModalVisible(false)
    }

    return (
        // <AnimationLayout duration={0.1}>
        <div className={classes.album} >
            <Modal isVisible={isDeleteModalVisible} toggleVisibility={cancelDelete} onSave={deleteAlbum} okBtnText={'Так'}>
                <div className={classes.deleteModalText}>
                    Ви впевнені, що бажаєте видалити?
                </div>
            </Modal>

            {imgDownloaded ? '' : <div className={classes.loading}><Loading /></div>}

            {!deletingAlbum ? <>
                {user && imgDownloaded ? <div className={classes["admin-buttons"]}>
                    <NavLink to={`${EDIT_ALBUM_INFO_ROUTE.path}/${album.id}`}>
                        <div className={classes.edit}>
                            <img src={EditIcon} alt="edit" />
                        </div>
                    </NavLink>
                    <div className={classes.del} onClick={showDeleteAlert}>
                        <img src={DelIcon} alt="delete" />
                    </div>
                </div> : ''}
                <NavLink to={`/albums/${album.id}`}>
                    <div className={classes.cover}>
                        <LazyLoadImg
                            image={{ alt: 'album poster', src: album.coverUrl }}
                            beforeLoad={loading}
                            afterLoad={loaded}
                        />
                        {/* <img src={album.coverUrl} alt="album cover" /> */}
                    </div>
                </NavLink>
                {imgDownloaded &&
                    <AnimationLayout duration={0.3}>
                        <div className={`${classes.details} ${classes[theme]}`}>
                            <div className={classes.title}>
                                {album.name}
                            </div>
                            {/* <NavLink to={`/albums/${album.id}`}>
                                <Button className={classes.linkDetails}>
                                    Детальніше
                                </Button>
                            </NavLink> */}
                        </div>
                    </AnimationLayout>}
            </> : <Loader />}
        </div>
        // </AnimationLayout>
    )
}

export default Album