import React from 'react'
import Advantage from './Advantage';
import AddAdvantages from '../Admin/AddAdvantages/AddAdvantages';
import useGetData from '../../hooks/useGetData';
import classes from './ListOfAdvantages.module.scss';

function ListOfAdvantages() {
    const { data, loading } = useGetData('advantages');

    return (
        <div className={classes.wrapper}>
            <h2 className={classes.title}>Чому саме ми ?</h2>
            <AddAdvantages />

            <div className={classes.advantages}>
                {!loading && data.map((item, idx) => (
                    <Advantage key={item.id} item={item} idx={idx} />
                ))}

                {!data.length && <div className="empty">Список пустий</div>}
            </div>
        </div>
    )
}

export default ListOfAdvantages