import { useEffect, useState } from 'react'
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/config';
import useIsUserLogin from '../../hooks/useIsUserLogin';
import { useNavigate } from 'react-router-dom';

import { HOME_ROUTE } from '../../utils/consts';

import classes from './Login.module.scss';
import Loader from '../../components/UI/Loader';

function Login() {
    const [registerEmail, setRegisterEmail] = useState("")
    const [registerPassword, setRegisterPassword] = useState("")
    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const [emailError, setEmailError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const navigate = useNavigate();

    const user = useIsUserLogin();

    const register = async () => {
        try {
            const user = await createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
            console.log(user);
        } catch (e) {
            console.log(e.message);
        }
    }

    const login = async () => {

        if (loginEmail.trim() === '') {
            setEmailError('Веддіть, буль ласка, електронну скриньку')
            return;
        }

        if (loginPassword.trim() === '') {
            setPasswordError('Веддіть, буль ласка, пароль')
            return;
        }

        try {
            setLoading(true);
            const user = await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
            navigate(HOME_ROUTE.path);
        } catch (e) {
            console.log(e.message);
        }
    }

    const logout = async () => {
        await signOut(auth)
    }

    const onChangeEmailInput = (e) => {
        setLoginEmail(e.target.value)

        if (emailError) {
            setEmailError(false);
        }
    }

    const onChangePasswordInput = (e) => {
        setLoginPassword(e.target.value)

        if (passwordError) {
            setEmailError(false);
        }
    }

    return (
        <>

            {loading &&
                <div className={classes.loading}>
                    <Loader />
                </div>
            }
            {/* <div>
                <h3>Register User</h3>
                <input placeholder="Email..." onChange={(e) => { setRegisterEmail(e.target.value) }} />
                <br />
                <input placeholder="Password..." onChange={(e) => { setRegisterPassword(e.target.value) }} />
                <br />

                <button onClick={register}> Create User </button>
            </div> */}

            {!user?.email && <>
                <h3>Введіть пошту та пароль </h3>
                <div className={classes.form}>
                    <div className={classes.group}>
                        <input placeholder="Email..." onChange={onChangeEmailInput} />
                        {emailError ? <span>{emailError}</span> : ''}
                    </div>
                    <div className={classes.group}>
                        <input placeholder="Password..." type="password" onChange={onChangePasswordInput} />
                        {passwordError ? <span>{passwordError}</span> : ''}
                    </div>
                    <div className={classes.group}>
                        <button onClick={login}> {!loading ? 'Увійти' : 'Завантаження'} </button>
                    </div>
                </div></>}

            {user?.email && <div className={classes.logged}>
                <h4> User Logged In: </h4>
                {user?.email}
                <br />
                <button onClick={logout}> Sign Out </button>
            </div>}
        </>
    )
}

export default Login