import { useState, useEffect } from 'react'
import { db } from '../services/firebase/config'
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore'

function useGetAlbumPhotos(albumId) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const collectionRef = collection(db, 'photos');

    const q = query(collection(db, "photos"), where("albumId", "==", albumId));

    useEffect(() => {
        const getData = async () => {
            // await getDocs(q).then(data => {
            //     data.forEach((doc) => {
            //         setData((prevState) => [...prevState, doc.data()])
            //         setLoading(false)
            //     });

            // });

            const q = query(collection(db, "photos"), where("albumId", "==", albumId), orderBy("orderIndex", "asc"));
            onSnapshot(q, (querySnapshot) => {
                setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                setLoading(false)
            });
        }

        getData()
    }, [])

    return { data, loading };
}

export default useGetAlbumPhotos