import { useState } from 'react'

import classes from './Modal.module.scss';

function Modal({ children, toggleVisibility, isVisible, onSave, isLoading = false, okBtnText = 'Зберегти' }) {
    return (
        <>
            {isVisible && <div className={classes.wrapper}>
                <div onClick={toggleVisibility} className={classes.bg}></div>

                <div className={isVisible ? `${classes["modal-wrapper"]} ${classes.modalOpen}` : `${classes["modal-wrapper"]} ${classes.modalClosed}`}>
                    <div className={classes.modal}>
                        {children}
                    </div>

                    {!isLoading ? <div className={classes.bottom}>
                        <div onClick={onSave} className={classes['btn-save']}> {okBtnText} </div>
                        <div onClick={toggleVisibility} className={classes['btn-cancel']}> Відмінити </div>
                    </div> : ''}
                </div>
            </div>}
        </>
    )
}

export default Modal