import React from 'react'

import Header from './Header'
import Footer from './Footer'

import classes from './Layout.module.scss';

function Layout({ children }) {
    return (
        <React.Fragment>
            <Header />

            <div className="container">
                {children}
            </div>

            <div className={classes.spacer}>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default Layout