import React from 'react'

import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

import { EDIT_ALBUM_INFO_ROUTE } from '../../../../utils/consts';
import { EDIT_ALBUM_PHOTOS_ROUTE } from '../../../../utils/consts';

import classes from './EditMenu.module.scss';

function EditMenu({ id, active }) {
    const theme = useSelector(({ ui }) => ui.theme)

    const classNames = `${classes.menu} ${classes[theme]}`

    return (
        <ul className={classNames}>
            <NavLink
                to={`${EDIT_ALBUM_INFO_ROUTE.path}/${id}`}><li className={active === 'info' ? classes.active : ''}> Основна інформація </li>
            </NavLink>

            <NavLink
                to={`${EDIT_ALBUM_PHOTOS_ROUTE.path}/${id}`}><li className={active === 'photos' ? classes.active : ''}> Фотографії </li>
            </NavLink>
        </ul>
    )
}

export default EditMenu