import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { db } from '../services/firebase/config'
import { collection, query, where, orderBy, onSnapshot, limit } from 'firebase/firestore'

function useGetAlbumsByCategory() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const catId = useSelector(({ category }) => category.activeCategory)

    useEffect(() => {
        const getData = async () => {
            let q;
            if (catId === 'all') {
                q = query(collection(db, "albums"), orderBy("name", "asc"));
            } else {
                q = query(collection(db, "albums"), orderBy("name", "asc"), where("categories", "array-contains-any", [catId]));
            }

            onSnapshot(q, (querySnapshot) => {
                setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                setLoading(false)
            });
        }

        getData()
    }, [catId])

    return { data, loading };
}

export default useGetAlbumsByCategory