import { useState, useEffect } from 'react'
import useIsUserLogin from '../../../hooks/useIsUserLogin';
import { useNavigate } from 'react-router-dom';
import { transformCategoriesData } from '../../../utils/transformCategoriesData';
import { getCategoriesIds } from '../../../utils/getCategoriesIds';
import { EDIT_ALBUM_PHOTOS_ROUTE } from '../../../utils/consts';
import Select from './Select';
import Input from './Input';
import useIsCommunionCategorySelected from '../../../hooks/useIsCommunionCategorySelected';
import ChangeOrderOfAlbumsBtn from '../ChangeOrderOfAlbums/ChangeOrderOfAlbumsBtn';
import { useSelector } from 'react-redux';

import Modal from '../../UI/Modal/Modal';

import { db, storage } from '../../../services/firebase/config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { collection, addDoc, getDoc } from 'firebase/firestore';
import useGetData from '../../../hooks/useGetData';

import classes from './AddAlbumButton.module.scss';
import Loader from '../../UI/Loader';

const initialValues = {
    name: "",
    pages: "",
    price: "",
    type: "",
    photosessions: "",
    portraits: "",
    size: "",
    categories: [],
    cover: []
}

const albumTypes = [
    { value: 'Бамбук', label: 'Бамбук', name: 'type' },
    { value: 'Прінтбук', label: 'Прінтбук', name: 'type' },
    { value: 'Планшетка', label: 'Планшетка', name: 'type' },
    { value: 'Слімбук', label: 'Слімбук', name: 'type' }
]

const albumsSizes = [
    { value: '20x30', label: '20x30', name: 'size' },
    { value: '30x30', label: '30x30', name: 'size' },
    { value: '23x23', label: '23x23', name: 'size' },
    { value: '30x20', label: '30x20', name: 'size' }
]

function AddAlbum() {
    const user = useIsUserLogin();
    const { data: categories } = useGetData('categories')

    const [isFormVisible, setIsFormVisible] = useState(false)
    const [savingAlbum, setSavingAlbum] = useState(false)
    const [values, setValues] = useState(initialValues);
    const [valuesErrors, setValuesErrors] = useState({});

    let activeCatId = useSelector(({ category }) => category.activeCategory)

    // const [switchOptions, setSwitchOptions] = useState(false);    
    const switchOptions = useIsCommunionCategorySelected(values.categories)

    const handleInputChange = (e) => {
        let name, value;

        if (e.target && e.target.name !== 'cover') {
            name = e.target.name;
            value = e.target.value
        } else if (Array.isArray(e)) {
            name = 'categories'
            value = e;
        } else if (e.target && e.target.name === 'cover') {
            name = e.target.name;
            value = e.target.files[0];
        } else {
            name = e.name;
            value = e.value;
        }

        setValuesErrors(prevState => ({
            ...valuesErrors,
            [name]: false
        }))

        setValues({
            ...values,
            [name]: value,
        });
    };

    const navigate = useNavigate();

    const toggleFormVisibility = () => {
        setIsFormVisible(prevState => !prevState);

        for (const key in values) {
            setValuesErrors(prevState => ({ ...prevState, [key]: false }))
        }
    }

    const onSaveAlbum = async () => {
        let error = false;

        for (const key in values) {
            if (Array.isArray(values[key])) {
                if (!values[key].length) {
                    setValuesErrors(prevState => ({ ...prevState, [key]: true }))
                    error = true
                }
            } else if (key === 'cover') {
                if (Array.isArray(values[key])) {
                    setValuesErrors(prevState => ({ ...prevState, [key]: true }))
                    error = true;
                }
            } else {
                if (!values[key].trim() && key !== 'cover') {
                    setValuesErrors(prevState => ({ ...prevState, [key]: true }))
                    error = true;
                }
            }
        }

        if (error) {
            return;
        }

        setSavingAlbum(true);
        const catIds = getCategoriesIds(values.categories);

        // add album to firebase
        try {
            const docRef = await collection(db, 'albums');
            const storageRef = ref(storage, `albumsImages/${Date.now() + '_' + values.cover.name}`);
            uploadBytesResumable(storageRef, values.cover).then(
                () => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        const savedAlbum = await addDoc(docRef, {
                            name: values.name,
                            pages: values.pages,
                            pagesType: values.type,
                            size: { label: values.size, value: values.size },
                            photosessions: values.photosessions,
                            portraits: values.portraits,
                            categories: catIds,
                            price: values.price,
                            coverUrl: downloadURL,
                            videoUrl: null
                        });

                        const docSnap = await getDoc(savedAlbum);
                        navigate(`${EDIT_ALBUM_PHOTOS_ROUTE.path}/${docSnap.id}`);
                    })
                }
            );

        } catch (e) {
            console.log(e)
        }
    }

    const popupContent = !savingAlbum ?
        <div className={classes.form}>
            <h3> Новий альбом </h3>

            <Input name="name" label={"Назва: "}
                value={values.name} type={"text"}
                error={valuesErrors.name}
                onChange={handleInputChange}
            />

            <Select
                defaultValue={values.type}
                onChange={handleInputChange}
                options={albumTypes}
                isMulti={false}
                placeholder={'Тип альбому'}
                error={valuesErrors.type}
                errorMsg={'Оберіть тип альбому!'}
                label={'Тип: '}
            />

            <Select
                defaultValue={values.categories}
                onChange={handleInputChange}
                options={transformCategoriesData(categories)}
                isMulti={true}
                label={'Категорії: '}
                placeholder={'Оберіть категорії'}
                error={valuesErrors.categories}
                errorMsg={'Оберіть категорію!'}
            />

            <Input
                name="pages"
                label={`Кількість ${values.type === 'Прінтбук' ? 'сторінок' : 'розворотів'}: `}
                value={values.pages}
                type={"number"}
                error={valuesErrors.pages}
                onChange={handleInputChange}
            />

            <Input
                name="photosessions"
                label={'Кількість фотосесій: '}
                value={values.photosessions}
                type={"number"}
                error={valuesErrors.photosessions}
                onChange={handleInputChange}
            />

            <Input
                name="portraits"
                label={switchOptions ? "Кількість фотографій: " : "Кількість портретів: "}
                value={values.portraits}
                type={"text"}
                error={valuesErrors.portraits}
                onChange={handleInputChange}
            />

            <Input
                name="price"
                label={"Ціна: "}
                value={values.price}
                type={"number"}
                error={valuesErrors.price}
                onChange={handleInputChange}
            />

            <Select
                defaultValue={values.size}
                onChange={handleInputChange}
                options={albumsSizes}
                isMulti={false}
                label={'Розмір: '}
                placeholder={'Оберіть розмір'}
                error={valuesErrors.size}
                errorMsg={'Оберіть розмір!'}
            />

            <Input
                name="cover"
                label={'Обкладинка: '}
                value={values.cover.filename}
                type={"file"}
                error={valuesErrors.cover}
                onChange={handleInputChange}
                errorMsg={'Оберіть зображення'}
            />
        </div>
        :
        <div className={classes.savingWrapper}>
            <Loader />
        </div>

    return (
        <>
            <Modal onSave={onSaveAlbum} toggleVisibility={toggleFormVisibility} isVisible={isFormVisible} isLoading={savingAlbum}>
                {popupContent}
            </Modal>

            {user &&
                <div className={classes.buttons}>
                    <div onClick={toggleFormVisibility} className={classes["add-btn"]}>Додати альбом</div>
                    {activeCatId !== 'all' && <ChangeOrderOfAlbumsBtn />}
                </div>
            }
        </>
    )
}

export default AddAlbum