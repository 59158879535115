import React from 'react'
import { useParams } from 'react-router-dom';

import ListOfAlbums from '../../components/ListOfAlbums'
import ListOfCategories from '../../components/ListOfCategories'

function Category() {

    const { id } = useParams();

    return (
        <div>
            Category
            ------------

            <ListOfCategories />
            <ListOfAlbums />
        </div>

    )
}

export default Category