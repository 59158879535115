import React from 'react'

import classes from './Input.module.scss';

function Input({ name, type, value, error, onChange, label, errorMsg }) {
    return (
        <>
            <div className={classes['form-group']}>
                <label htmlFor={name}>{label}</label>
                <div className={classes.input}>
                    <input min='1' id={name} type={type} name={name} value={value} onChange={onChange} />
                    {error && <span>{errorMsg ? errorMsg : 'Це поле не повинно бути пустим!'}</span>}
                </div>
            </div>
        </>

    )
}

export default Input