import Menu from '../../Menu/Menu';
import ThemeSwitcher from '../../ThemeSwitcher';
import Logo from '../../Logo';
import { useSelector } from 'react-redux';

import classes from './Header.module.scss';

export default function Header(props) {
  const isDarkTheme = useSelector(({ ui }) => ui.isDark);

  return (
    <div className={`${classes.header} ${isDarkTheme ? classes.dark : ''}`}>
      <Logo />
      <Menu />
      <ThemeSwitcher />
    </div>
  );
}
