import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import AnimationLayout from './components/AnimationLayout';

import Home from './pages/Home';
import Contacts from './pages/Contacts';
import Albums from './pages/Albums';
import AlbumView from './pages/AlbumView';
import Login from './pages/Login';
import AdminPanel from './components/Admin/AdminPanel/AdminPanel';
import EditCategories from './components/Admin/EditCategories';
import useIsUserLogin from './hooks/useIsUserLogin';
import Category from './pages/Category/Category';

import EditInfo from './components/Admin/EditAlbum/EditInfo';
import UploadPhotos from './components/Admin/EditAlbum/UploadPhotos';

import { HOME_ROUTE } from './utils/consts';
import { ALBUMS_ROUTE } from './utils/consts';
import { CONTACTS_ROUTE } from './utils/consts';
import { LOGIN_ROUTE } from './utils/consts';
import { EDIT_ALBUM_PHOTOS_ROUTE } from './utils/consts';
import { EDIT_ALBUM_INFO_ROUTE } from './utils/consts';
import { EDIT_CATEGORIES_ROUTE } from './utils/consts';
import { EDIT_ORDER_OF_ALBUMS_ROUTE } from './utils/consts';

import './App.css';
import ChangeOrderOfAlbums from './components/Admin/ChangeOrderOfAlbums/ChangeOrderOfAlbums';

function App() {
  const theme = useSelector(({ ui }) => ui.theme)

  const user = useIsUserLogin()

  return (
    <div className={`app ${theme}`} >
      <AdminPanel />

      <Layout>
        <AnimationLayout>
          <Routes>
            <Route path={HOME_ROUTE.path} element={<Home />} />
            <Route path={CONTACTS_ROUTE.path} element={<Contacts />} />
            <Route path={ALBUMS_ROUTE.path} element={<Albums />} />
            <Route path={`${ALBUMS_ROUTE.path}/:id`} element={<AlbumView />} />
            <Route path={`${ALBUMS_ROUTE.path}/category/:id`} element={<Albums />} />
            <Route path={LOGIN_ROUTE.path} element={<Login />} />

            {user &&
              [
                <Route key={EDIT_ALBUM_INFO_ROUTE.path} path={`${EDIT_ALBUM_INFO_ROUTE.path}/:id`} element={<EditInfo />} />,
                <Route key={EDIT_ORDER_OF_ALBUMS_ROUTE.path} path={`${EDIT_ORDER_OF_ALBUMS_ROUTE.path}`} element={<ChangeOrderOfAlbums />} />,
                <Route key={`${EDIT_ALBUM_PHOTOS_ROUTE.path}/:id`} path={`${EDIT_ALBUM_PHOTOS_ROUTE.path}/:id`} element={<UploadPhotos />} />,
                <Route key={EDIT_CATEGORIES_ROUTE.path} path={EDIT_CATEGORIES_ROUTE.path} element={<EditCategories />} />
              ]
            }

          </Routes>
        </AnimationLayout>
      </Layout>

    </div >
  );
}

export default App;
