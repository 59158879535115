import React from 'react'

import classes from './Contacts.module.scss';
import Iframe from 'react-iframe'

import contactsImg from '../../assets/images/contacts.png';

function Contacts() {
    return (
        <div className={classes.contacts}>

            <div className={classes.img}>
                <img src={contactsImg} alt="контакти" />
            </div>

            <div className={classes.block}>
                <div className={classes.label}>Адреса:</div>
                <div className={classes.info}>м. Стрий, вулиця Хмельницького, 8</div>
            </div>

            <div className={classes.block}>
                <div className={classes.label}>Телефони:</div>

                <div className={classes.info}>
                    <a href="tel:+380938154903">+380938154903</a>
                    <br />
                    <a href="tel:+380938154903">+380734344228</a>
                </div>
            </div>

            <div className={classes["map-wrapper"]}>
                <Iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10416.00188100763!2d23.8555141!3d49.257437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473a69f59a25a9f3%3A0x3dd21b23c956ac95!2sZebra%20Fotostudiya!5e0!3m2!1sen!2sua!4v1679053347531!5m2!1sen!2sua" className={classes.map} width="100%" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
            </div>

        </div >
    )
}

export default Contacts