import { createSlice } from '@reduxjs/toolkit'

const theme = localStorage.getItem('theme') !== null ? localStorage.getItem('theme') : 'light';

const initialState = {
    theme
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        changeTheme: (state, action) => {
            const themeName = action.payload ? 'dark' : 'light';
            state.theme = themeName;

            localStorage.setItem('theme', state.theme);
        }
    },
})

// Action creators are generated for each case reducer function
export const { changeTheme } = uiSlice.actions

export default uiSlice.reducer