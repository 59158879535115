import React from 'react'
import useIsUserLogin from '../../../hooks/useIsUserLogin';
import { signOut } from 'firebase/auth';
import { auth } from '../../../services/firebase/config';

import classes from './AdminPanel.module.scss';

function AdminPanel() {
    const user = useIsUserLogin()

    const logout = async () => {
        await signOut(auth)
    }

    return (
        <>
            {user?.email &&
                <div className={classes.panel}>
                    {user.email}<div className={classes.logout} onClick={logout}> logout </div>
                </div>
            }
        </>
    )
}

export default AdminPanel