import { configureStore } from '@reduxjs/toolkit'

import uiReducer from './reducers/uiSlice'
import categorySlice from './reducers/categorySlice'

export const store = configureStore({
    reducer: {
        ui: uiReducer,
        category: categorySlice
    },
})