import React from 'react';
import { useLocation } from 'react-router-dom';

import { motion } from "framer-motion";

function AnimationLayout({ children, delay, duration, initial, animate }) {
    const { pathname } = useLocation();

    const pageVariants = {
        initial: {
            opacity: 0
        },
        in: {
            opacity: 1
        },
        out: {
            opacity: 0
        }
    };

    const pageTransition = {
        type: "tween",
        ease: "linear",
        duration: duration ? duration : .3,
        delay: delay ? delay : 0
    };

    return (
        <motion.div
            key={pathname}
            initial={initial ? { ...initial } : "initial"}
            animate={animate ? { ...animate } : "in"}
            variants={pageVariants}
            transition={pageTransition}
            exit={{ y: -10, opacity: 0 }}
        >

            {children}

        </motion.div >
    )
}

export default AnimationLayout