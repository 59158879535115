export const getCategoriesIds = (array) => {
    const categoriesIds = [];

    console.log(array)

    for (let i = 0; i < array.length; i++) {
        categoriesIds.push(array[i].id);
    }

    return categoriesIds;
}