
import React from "react";
import Switch from "react-switch";
import classes from './Switch.module.scss';

const ToggleSwitch = ({ onChange, defaultValue, onIcon, offIcon }) => {
    const [checked, setChecked] = React.useState(defaultValue);

    function handleChange(checked) {
        setChecked((prevState) => !prevState);
        onChange(checked);
    }

    const checkedIcon =
        onIcon ?
            <div className={classes.icon}>
                <img src={onIcon} alt="" />
            </div> : '';

    const uncheckedIcon =
        offIcon ?
            <div className={classes.icon}>
                <img src={offIcon} alt="" />
            </div> : '';


    return (
        <label>
            <Switch
                onChange={handleChange}
                checked={checked}
                onColor="#161616"
                checkedIcon={checkedIcon}
                uncheckedIcon={uncheckedIcon}
            />
        </label>
    );
};

export default ToggleSwitch;