import React from 'react'

import ReactSelect from 'react-select';

import classes from './Select.module.scss';

function Select({ defaultValue, label, onChange, options, isMulti, placeholder, error, errorMsg }) {
    return (
        <div className={`${classes['form-group']}`}>
            <label>{label}</label>
            <div className={classes.input}>
                <ReactSelect
                    defaultValue={defaultValue}
                    onChange={onChange}
                    options={options}
                    isMulti={isMulti}
                    placeholder={placeholder}
                />

                {error && <span>{errorMsg ? errorMsg : 'Поле не повинно бути пустим!'}</span>}
            </div>
        </div>
    )
}

export default Select