import React from 'react'

import { useSelector } from 'react-redux';

import instagramIcon from '../../../assets/images/instagram.png';
import facebookIcon from '../../../assets/images/facebook.png';

import classes from './Footer.module.scss';

export default function Footer() {
    const theme = useSelector(({ ui }) => ui.theme);

    return (
        <div className={`${classes.footer} ${classes[theme]}`}>
            <div className={classes.socials}>
                <div className={classes.social}>
                    <a href="https://www.instagram.com/brotherskhoma.album/" target="_blank">
                        <img src={instagramIcon} alt="instagram" />
                    </a>
                </div>
                <div className={classes.social}>
                    <a href="https://www.facebook.com/ZebraStryi" target="_blank">
                        <img src={facebookIcon} alt="facebook" />
                    </a>
                </div>
            </div>
            <div className={classes.developer}>
                Розробник <a href="https://www.antonvasylchenko.com"> Антон Васильченко</a>
            </div>
        </div>
    )
}
