import { useState, useEffect } from 'react'
import { db } from '../services/firebase/config'
import { collection, onSnapshot } from 'firebase/firestore'

function useGetData(collectionName) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const collectionRef = collection(db, collectionName);

    useEffect(() => {
        const getData = async () => {
            await onSnapshot(collectionRef, (snapshot) => {
                setData(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
                setLoading(false)
            });

            // const q = query(collection(db, "photos"), where("albumId", "==", albumId), orderBy("orderIndex", "asc"));
            // onSnapshot(q, (querySnapshot) => {
            //     setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
            //     setLoading(false)
            // });
        }

        getData()
    }, [])

    return { data, loading };
}

export default useGetData