import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activeCategory: 'all',
    albumsOrder: [],
    name: 'Всі'
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        changeCategory: (state, action) => {
            state.activeCategory = action.payload.id;
            state.albumsOrder = action.payload.albumsOrder;
            state.name = action.payload.name;
        }
    },
})

// Action creators are generated for each case reducer function
export const { changeCategory } = categorySlice.actions

export default categorySlice.reducer