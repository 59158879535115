import React from 'react'

import classes from './Loader.module.scss';

function Loader() {
    return (
        <div className={classes.holder}>
            <div className={classes.preloader}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader