import React from 'react'

import classes from './Loading.module.scss';

function Loading() {
    return (
        <div className={classes["lds-spinner"]}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    )
}

export default Loading