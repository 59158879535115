import React from 'react';

import classes from './AlbumView.module.scss';
import { useParams } from 'react-router-dom';
import { db } from '../../services/firebase/config';
import { doc } from 'firebase/firestore';
import { getDoc } from 'firebase/firestore';
import { transformPhotosData } from '../../utils/transformPhotosData';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { isMobile } from 'react-device-detect';
import useGetData from '../../hooks/useGetData';
import { EDIT_ALBUM_INFO_ROUTE } from '../../utils/consts';
import { NavLink } from 'react-router-dom';
import useIsUserLogin from '../../hooks/useIsUserLogin';
import EditIcon from '../../assets/images/edit-icon.png';
import BackArrow from '../../components/UI/BackArrow'

import useGetAlbumPhotos from '../../hooks/useGetAlbumPhotos';

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import { useEffect } from 'react';
import { useState } from 'react';
import Loader from '../../components/UI/Loader';

function AlbumView() {
    const { id } = useParams();
    const user = useIsUserLogin();

    const docRef = doc(db, 'albums', id)
    const { data: photos, loading: loadingPhotos } = useGetAlbumPhotos(id);
    const { data: categories } = useGetData('categories')

    const [album, setAlbum] = useState([])
    const [loading, setLoading] = useState(true)
    const [albumCategories, setAlbumCategories] = useState([])


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
    })

    useEffect(() => {
        const getAlbum = async () => {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setAlbum(docSnap.data())

                const albumCategoriesIds = docSnap.data().categories

                categories.forEach(cat => {
                    albumCategoriesIds.forEach(item => {
                        if (cat.id === item) {
                            setAlbumCategories(prevState => (
                                [...prevState, cat.name.toLowerCase()]
                            ))
                        }
                    })
                })

                setLoading(false);

            } else {
                console.log('no album!');
            }
        }

        getAlbum()
    }, [categories])

    return (
        <LazyLoadComponent>
            {!loading ? <div className={classes.album}>

                <div className={classes.title}>
                    {album.name}

                    {user ? <div className={classes["admin-buttons"]}>
                        <NavLink to={`${EDIT_ALBUM_INFO_ROUTE.path}/${id}`}>
                            <div className={classes.edit}>
                                <img src={EditIcon} alt="edit" />
                            </div>
                        </NavLink>
                    </div> : ''}
                </div>

                {photos.length !== 0 && <div className={classes.slider}>
                    {!loadingPhotos ?
                        <Carousel
                            images={transformPhotosData(photos)}
                            transitionSpeed={100}
                            hasTransition={isMobile}
                            shouldLazyLoad={true}
                            playIcon={false}
                            hasLeftButton={isMobile ? false : 'centerLeft'}
                            hasRightButton={isMobile ? false : 'centerRight'}
                            style={{ background: 'none', height: '100%', width: '100%' }} />
                        : <Loader />}
                </div>}

                {album.videoUrl && <div className={classes.video}>
                    <iframe width="100%" height="100%" frameborder="0"
                        src={album.videoUrl} title="video">
                    </iframe>
                </div>}

                <div className={classes.options}>
                    {user ? <div className={classes["admin-buttons"]}>
                        <NavLink to={`${EDIT_ALBUM_INFO_ROUTE.path}/${id}`}>
                            <div className={classes.edit}>
                                <img src={EditIcon} alt="edit" />
                            </div>
                        </NavLink>
                    </div> : ''}

                    <div className={classes.info}>
                        <div className={classes.label}> Розмір альбому: </div>
                        {album?.size && album.size.label}
                    </div>

                    <div className={classes.info}>
                        <div className={classes.label}>
                            Тип сторінок:
                        </div>
                        {album.pagesType === 'Прінтбук' ? `Гнучкі аркуші поліграфічного типу. Можлива ламінація (+100 грн)` : `Тверді ламіновані листи з розкриттям блоку на 180°`}
                    </div>

                    <div className={classes.info}>
                        <div className={classes.label}>
                            {album.pagesType === 'Прінтбук' ? 'Кількість сторінок:' : 'Кількість розворотів:'}
                        </div>
                        {album.pages} ({album.pagesType !== 'Прінтбук' ? `${album.pages * 2} сторінок` : `${album.pages / 2} аркушів`})
                    </div>

                    {albumCategories.includes('причастя') ||

                    <div className={classes.info}>
                        <div className={classes.label}> Загальна кількість фотосесій: </div>
                        {album.photosessions} (Додаткова фотосесія +250 грн. Разом з додатковою фотосесією до альбому додається {album.pagesType !== 'Прінтбук' ? `2 розвороти (4 сторінки)` : `8 сторінок (4 аркуша)`})
                    </div>}

                    <div className={classes.info}>
                        <div className={classes.label}> {albumCategories.includes('причастя') || albumCategories.includes('святе причастя') ? "Кількість фотографій в альбомі:" : "Кількість портретів:"} </div>
                        {album.portraits} (додатковий портрет + 50 грн)
                    </div>

                    <div className={classes.info}>
                        <div className={classes.label}> Ціна: </div>
                        {album.price} грн / шт. (При замовленні від 10 альбомів)
                    </div>

                    <span>
                        {albumCategories.includes('причастя') || albumCategories.includes('святе причастя') ? `Фотографування причастя в ціну альбому не входить. Ціна вказана тільки за альбом!` : "Також разом з альбомами віддаємо усі фотографії в цифровому варіанті!"}                                                
                    </span>
                </div>

                <div className={`${classes.options} ${classes.contacts}`}>
                    <div className={classes.title}>
                        Який термін виготовлення альбомів?
                    </div>

                    <div className={classes.phones}>
                        Від 1 до 3 місяців після подачі всіх необхідних матеріалів для розробки альбому.
                        Терміни залежить від обраного макету, а також від кількості портретів та фотосесій
                    </div>
                </div>
                
                <div className={`${classes.options} ${classes.contacts}`}>
                    <div className={classes.title}>
                        Як замовити?
                    </div>

                    <div className={classes.phones}>
                        Надзвичайно просто! Телефонуйте за номером: +380938154903
                    </div>
                </div>
            </div> : <Loader />}
        </LazyLoadComponent>
    )
}

export default AlbumView