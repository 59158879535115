import React from 'react'
import { Link } from 'react-router-dom';
import { EDIT_ORDER_OF_ALBUMS_ROUTE } from '../../../../utils/consts';

import classes from './ChangeOrderOfAlbumsBtn.module.scss';

function ChangeOrderOfAlbumsBtn() {
    return (
        <div className={classes.wrapper}>
            <Link to={`${EDIT_ORDER_OF_ALBUMS_ROUTE.path}`} >
                <div className={classes["change-order"]}> Змінити послідовність </div>
            </Link >
        </div>
    )
}

export default ChangeOrderOfAlbumsBtn