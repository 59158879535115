import React from 'react'
import ListOfAlbums from '../../components/ListOfAlbums'
import ListOfCategories from '../../components/ListOfCategories'
import AddAlbum from '../../components/Admin/AddAlbum/AddAlbum'

function Albums() {
    return (
        <React.Fragment>
            <ListOfCategories />
            <AddAlbum />
            <ListOfAlbums />
        </React.Fragment>
    )
}

export default Albums