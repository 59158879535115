import React from 'react'
import classes from './Alert.module.scss';
import { useState } from 'react';

function Alert({ children, type, visibility, close }) {
    const className = type === 'error' ? 'error' : 'success'

    return (
        <>
            {visibility && <div className={classes[className]}>
                <div className={classes.close} onClick={() => close(false)}> x </div>
                {children}
            </div>}
        </>
    )
}

export default Alert