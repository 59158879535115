import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import classes from './LazyLoadImg.module.scss';

function LazyLoadImg({ image, beforeLoad, afterLoad }) {
    return (
        <LazyLoadImage
            alt={image.alt}
            effect="blur"
            src={image.src}
            beforeLoad={beforeLoad}
            afterLoad={afterLoad}
        />
    )
}

export default LazyLoadImg