import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { transformCategoriesData } from '../../../../utils/transformCategoriesData';
import { getCategoriesIds } from '../../../../utils/getCategoriesIds';
import { useNavigate } from 'react-router-dom';
import useIsCommunionCategorySelected from '../../../../hooks/useIsCommunionCategorySelected';

import { db, storage } from '../../../../services/firebase/config';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import Button from '../../../UI/Button/Button';

import useGetData from '../../../../hooks/useGetData';

import classes from './EditInfo.module.scss';
import EditMenu from '../EditMenu';
import Loader from '../../../UI/Loader';

import { ALBUMS_ROUTE } from '../../../../utils/consts';

function EditInfo() {
    const { id } = useParams();

    const navigate = useNavigate();

    const [enteredName, setEnteredName] = useState('')
    const [enteredPages, setEnteredPages] = useState('')
    const [enteredPhotosessions, setEnteredPhotosessions] = useState('')
    const [enteredPortraits, setEnteredPortraits] = useState('')
    const [enteredPrice, setEnteredPrice] = useState('')
    const [enteredImage, setEnteredImage] = useState(null)
    const [currentImage, setCurrentImage] = useState(null)
    const [selectedType, setSelectedType] = useState({ type: 'Прінтбук', label: 'Прінтбук' });
    const [selectedSize, setSelectedSize] = useState({ type: 'Прінтбук', label: 'Прінтбук' });
    const [enteredVideoUrl, setEnteredVideoUrl] = useState('')

    const { data: categories, loading } = useGetData('categories')

    const [selectedCategories, setSelectedCategories] = useState([]);
    const isCommunion = useIsCommunionCategorySelected(selectedCategories)

    useEffect(() => {
        const docRef = doc(db, 'albums', id)
        const getAlbum = async () => {
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setEnteredName(docSnap.data().name)
                console.log(docSnap.data().name)
                setEnteredPages(docSnap.data().pages)
                setEnteredPortraits(docSnap.data().portraits)
                setEnteredPhotosessions(docSnap.data().photosessions)
                setEnteredPrice(docSnap.data().price)
                setCurrentImage(docSnap.data().coverUrl)
                setEnteredVideoUrl(docSnap.data().videoUrl)
                setSelectedCategories(selectedCategoriesData(docSnap.data().categories))
                setSelectedType({ value: docSnap.data().pagesType, label: docSnap.data().pagesType })
                setSelectedSize(docSnap.data().size)

                console.log(selectedCategoriesData(docSnap.data().categories))
            } else {
                console.log('no album!');
            }
        }
        getAlbum()
    }, [categories])

    const onSaveAlbum = async () => {

        if (!enteredName.trim()) return;
        if (!enteredPages.trim()) return;
        if (!enteredPrice.trim()) return;
        if (!enteredPortraits.trim()) return;
        if (!enteredPhotosessions.trim()) return;

        const docRef = doc(db, 'albums', id)
        const catIds = getCategoriesIds(selectedCategories);

        if (!enteredImage) {
            updateDoc(docRef, {
                name: enteredName,
                pages: enteredPages,
                photosessions: enteredPhotosessions,
                portraits: enteredPortraits,
                price: enteredPrice,
                categories: catIds,
                videoUrl: enteredVideoUrl,
                pagesType: selectedType.value
            })
        } else {
            const coverRef = ref(storage, `${currentImage}`);

            deleteObject(coverRef).then(() => {
                // File deleted successfully
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });

            const storageRef = ref(storage, `albumsImages/${Date.now() + '_' + enteredImage.name}`);
            uploadBytesResumable(storageRef, enteredImage).then(
                () => {
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                        await updateDoc(docRef, {
                            name: enteredName,
                            pages: enteredPages,
                            photosessions: enteredPhotosessions,
                            portraits: enteredPortraits,
                            price: enteredPrice,
                            coverUrl: downloadURL,
                            videoUrl: enteredVideoUrl,
                            pagesType: selectedType.value
                        });
                    })
                }
            );
        }

        navigate(ALBUMS_ROUTE.path)
    }

    const imgUrl = !enteredImage ?
        `${currentImage}` : URL.createObjectURL(enteredImage);

    const selectedCategoriesData = (ids) => {
        const albumsCategories = categories.filter((cat) => {
            let category;
            for (let i = 0; i < ids.length; i++) {
                if (cat.id === ids[i]) {
                    category = cat;
                }
            }
            return category;
        })
        return transformCategoriesData(albumsCategories);
    }

    return (
        <>
            {loading ? <Loader /> : <div>
                <h3> Редагування альбому </h3>
                <EditMenu id={id} active={'info'} />
                <div className={classes.form}>
                    <div className={classes['form-group']}>
                        <label htmlFor="name">Назва: </label>
                        <div className={classes.inputWrapper}>
                            <input id="name" type="text" value={enteredName} onChange={(e) => { setEnteredName(e.target.value) }} />
                            {enteredName.trim() === '' && <span>Це поле не може бути пустим!</span>}
                        </div>
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="pages">Кількість сторінок: </label>
                        <div className={classes.inputWrapper}>
                            <input min='1' id="pages" type="number" value={enteredPages} onChange={(e) => { setEnteredPages(e.target.value) }} />
                            {enteredPages.trim() === '' && <span>Це поле не може бути пустим!</span>}
                        </div>
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="photosessions">Кількість фотосесій: </label>
                        <div className={classes.inputWrapper}>
                            <input min='1' id="photosessions" type="number" value={enteredPhotosessions} onChange={(e) => { setEnteredPhotosessions(e.target.value) }} />
                            {enteredPhotosessions.trim() === '' && <span>Це поле не може бути пустим!</span>}
                        </div>
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="portraits">Кількість {isCommunion ? "фотографій:" : "портретів:"} </label>
                        <div className={classes.inputWrapper}>
                            <input min='1' id="portraits" type="number" value={enteredPortraits} onChange={(e) => { setEnteredPortraits(e.target.value) }} />
                            {enteredPortraits.trim() === '' && <span>Це поле не може бути пустим!</span>}
                        </div>
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="price">Ціна: </label>
                        <div className={classes.inputWrapper}>
                            <input min='1' id="price" type="number" value={enteredPrice} onChange={(e) => { setEnteredPrice(e.target.value) }} />
                            {enteredPrice.trim() === '' && <span>Це поле не може бути пустим!</span>}
                        </div>
                    </div>

                    <div className={classes['form-group']}>
                        Тип альбому:
                        <Select
                            defaultValue={selectedType}
                            value={selectedType}
                            onChange={setSelectedType}
                            options={[{ value: 'Бамбук', label: 'Бамбук' }, { value: 'Прінтбук', label: 'Прінтбук' }, { value: 'Планшетка', label: 'Планшетка' }, { value: 'Слімбук', label: 'Слімбук' }]}
                            isMulti={false}
                            placeholder={'Виберіть категорію'}
                        />
                    </div>

                    <div className={classes['form-group']}>
                        Розмір:
                        <Select
                            defaultValue={selectedSize}
                            value={selectedSize}
                            onChange={setSelectedSize}
                            options={[
                                { value: '20x30', label: '20x30', name: 'size' },
                                { value: '30x30', label: '30x30', name: 'size' },
                                { value: '23x23', label: '23x23', name: 'size' },
                                { value: '30x20', label: '30x20', name: 'size' }
                            ]}
                            isMulti={false}
                            placeholder={'Виберіть категорію'}
                        />
                    </div>

                    <div className={classes['form-group']}>
                        Категорії:
                        <Select
                            defaultValue={selectedCategories}
                            value={selectedCategories}
                            onChange={setSelectedCategories}
                            options={transformCategoriesData(categories)}
                            isMulti={true}
                            placeholder={'Виберіть категорії'}
                        />
                    </div>

                    <div className={classes['form-group']}>
                        <label htmlFor="video">Посилання на відео (не обовязково) : </label>
                        <div className={classes.inputWrapper}>
                            <input min='1' id="video" type="text" value={enteredVideoUrl ? enteredVideoUrl : ''} onChange={(e) => { setEnteredVideoUrl(e.target.value) }} />
                        </div>
                    </div>

                    <img src={imgUrl} alt="" />

                    <div className={classes['form-group']}>
                        <label htmlFor="cover">Картинка: </label>
                        <div className={classes.inputWrapper}>
                            <input id="cover" type="file" onChange={(e) => { setEnteredImage(e.target.files[0]) }} />
                        </div>
                    </div>

                    <div className={classes.bottom}>
                        <Button onClick={onSaveAlbum} className={classes['btn-save']}> Зберегти </Button>
                        <Button onClick={() => navigate(-1)}> Назад </Button>
                    </div>
                </div>
            </div >}
        </>
    )
}

export default EditInfo