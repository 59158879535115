import { useState, useEffect } from 'react'

function useIsCommunionCategorySelected(categories) {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {

        const check = categories.filter(item => {
            if (item.label.toLowerCase().includes('причастя')
                || item.label.toLowerCase().includes('сімейна')
                || item.label.toLowerCase().includes('сімейні')
                || item.label.toLowerCase().includes('весільні')
                || item.label.toLowerCase().includes('весілля')) {
                return true;
            }
        })
        if (check.length) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    })

    return isActive;
}

export default useIsCommunionCategorySelected